/**
 * 此文件是全局通用poskey, 加poskey请备注用途.
 * */

/* 全局（公共） */
const APP_POSKEY = {
  newPosKeys: [
    'selectcolor', // 快速加车
    // 'SizeTips', // 快速加车
    'NewSheinClub', // 付费会员
    'goodsPicAb', // AB图
    'GoogleAutoSign', // 谷歌一键登录
    'similaritemsnew', // 推荐策略
    'popupssoldoutsimilar', // 找相似浮层控制
    'PhoneAreaCodePopop', // 手机号弹窗
    'BackToSugg', // 搜索结果页回退到联想词列表
    'swPreCache', // 首页 sw HTML 缓存
    'AccountManager',
    'HomePopup', // 全局弹窗队列onetrust队列优先级
    'Similaritems', // 预搜页查找相似商品 ---bff
    'SearchSimilar', // 预搜页查找相似商品 ---bff
    'SearchTrendNew', // 预搜页趋势榜 ---bff
    'PerformanceLogNodeTestFetch', // 性能日志用于测试人员命中后展示弹框
    'flowshowsearch', // 信息流落地页头部搜索框吸顶+平铺
  ],
  posKeys: [
    'Movepoints',
    'AppShell',
    // 'SMDeviceId',
    'Aod', // 精投
    // 'CccListFlow',  // 已推全-写死值: ShowOrderApplication
    'Branch',
    // 快加车
    'Bodysize'
  ]
}

/* 头部组件（公共） */
const HEADER_POSKEY = {
  newPosKeys: [
    'NewPicSearch',
    'HomeSearch',
    'PicSearchUpgrade',
    'hovercart',
    'listiconBag',
    'ListEntrance',
    'ListTitleType',
    'SearchDefaultNew',
    'SearchHotGD',
    'Listshowsearch',
    'newsearch', // 搜索框样式强化
    'ListShadingWord',
    'storeiconchange',
    'DetailShadingWord', // 商详底纹词新策略：🔍框样式+底纹词
    'collectandaddcart',
    'flowfirmsearch',
    'pfirmsearch',
    'h1firmsearch',
    'sbcfirmsearch',
    'catefirmsearch',
  ],
  posKeys: [
    'RotationShow',
    'ShowSearch',
    'PicSearch',
    'SearchSuggestwordFeedback',
    'PicSearchStrategy',
  ]
}

/* 尾部组件（公共） */
const FOOTER_POSKEY = {
  newPosKeys: [
    'Cartentranceinfo', // 全局购物车入口展示利诱点信息
    'CartEntranceInfoSwitchNew', // 全局购物车入口展示利诱点信息
    'CartEntranceInfoNewuser', // 全局购物车入口利诱展示新客信息
    'Cartshowcoupon', // Cartentranceinfo 的关联 abt
    'listcartinfoSwitch', // 购物车浮窗
  ],
  posKeys: []
}

/* 快速注册（公共） */
const QUICK_REGISTE_POSKEY = [
  'FastRegisterPopup', // 新隐私政策
  'newPrivacy'
]

/* 推荐列表（公共） */
const RECOMMEND_POSKEY = {
  newPosKeys: [
    'greysellingPoint',
    'discountLabel',
    'addtobagDetail',
    'colorDetail',
    'imageLabel',
    'RecoLoadmore',
    'recSwitch',
    'recnewCard',
    'recmultiCard',
    'salesLabel',
    'rrankinglabelclick',
    'carddiscountLabel',
    'drankinglabelclick',
    'listranking',
    'DetailStarReview',
    'detailKeyAttribute',
    'detailgoodsCard',
    'FlashSaleCountDown',
    'listattributeLabels',
    'EstimatedPrice',
    'listflashSale',
    'listtagSorting',
    'listrankingTag',
    'listquickship', // 新quickship标签
    'listquickshipKey', // 新quickship标签
    'listnewuserOnly', // 新客腰带
    'rcmdtrendTag', // 趋势标签
    'listwithCoupon', // 到手价新样式
    'listwithS3', // S3会员价格展示优化
    'listquickshipLanguage', 
    'listpriceTimeTag', 
    'listpricetagNew'
  ],
  posKeys: [
    'RecommendExpandPop',
    'NoClothingDetailOftenBoughWithExpandPop',
    'ProductDetailYouMayAlsoLikeExpandPop'
  ]
}

/*  精投 */
const campaignPoskey = new Array(10).fill('ActAbt').map((v, i) => `${v}${i + 1}`)
const ACT_POSKEY = {
  posKeys: ['ActAbt', ...campaignPoskey]
}

/* 优惠卷弹框 */
const COUPON_POSKEYS = {
  newPosKeys: [
    'CouponPattern',
    'CouponBagUITest',
    'ReturnInform',
    'CodeExpand',
    'CouponTimeFormatChange',
    'CouponbagUpdate',
    'SearchListCoupon',
    'SearchSceneCoupon',
    'CouponWindowsNew',
  ],
  posKeys: ['PostAmount']
}

/* 卡支付/卡分期前置 */
const PRE_PAYMENT_POSKEY = {
  newPosKeys: [
    'PaymentSecurity' // 卡支付安全提示
  ]
}

// todo: 待梳理的poskey，请勿新增
const OTHER_POSKEY = {
  newPosKeys: [
    'ShippingLogisticsTime',
    'ShowPromotion',
    'SameLabel',
    'RankingList',
    'VerifyCodeSwitch',
    'allshiptimeshow',
  ],
  posKeys: [
    'Share',
    // 短信
    'SmsN',
    // 免邮凑单数据
    'Add',
    // us站双语弹窗
    // 'pop',
    'Orderlist',
    'Orderdetail',
    // 履约异常
    'SpecialOutgoingScene',
    'DeleteOrder',
    'SelfExchange',
    // 支付方式过滤
    'PaymentABT',
    'CODwithdraw',

    // 个人中心引导下载
    // 'MeDownloadBlock',
    // 标签云
    // 'LabelMore', 推全type=C
    // 卖点
    'SellingPoint',
    'GoogleOneTapSignIn',
    // 红利Bonus Day
    // 'OpenBonusDay',
    'Flashsale',
    'SubFlashSale',
    // 直连支付
    'PayInline',
    'CookieResult',
    // 多主体隐私政策查询
    'SilentloginPrivacy',
    // 快速注册弹窗
    'Quickregclosed',
    'ItemAddToBoard',
    // 'LookBookNew',
    'SearchCard',
    'SearchRecTips',
    'sheinclubprice',
    'AddShowGroup',
    //闪购分人群
    'FlashShowedCrowed',
    'brandstoreobm',
    'FixedPriceRecomend',
    'OrderTrackYouMayAlsoLike' // 上报实验  todo: 移除到自己页面中
  ]
}

export default {
  newPosKeys: [
    ...APP_POSKEY.newPosKeys,
    ...HEADER_POSKEY.newPosKeys,
    ...FOOTER_POSKEY.newPosKeys,
    ...RECOMMEND_POSKEY.newPosKeys,
    ...COUPON_POSKEYS.newPosKeys,
    ...PRE_PAYMENT_POSKEY.newPosKeys,
    ...OTHER_POSKEY.newPosKeys
  ],
  posKeys: [
    ...HEADER_POSKEY.posKeys,
    ...FOOTER_POSKEY.posKeys,
    ...APP_POSKEY.posKeys,
    ...QUICK_REGISTE_POSKEY,
    ...RECOMMEND_POSKEY.posKeys,
    ...ACT_POSKEY.posKeys,
    ...COUPON_POSKEYS.posKeys,
    ...OTHER_POSKEY.posKeys
  ]
}
