export default {
  routeName: 'CustomsInterception',
  newPosKeys: [
    'SyncToUseraddress',
    'BatchModifyAddress',
    'returnUrgeFront',
    'showCombineReturn'
  ],
  posKeys: [],
}
