import schttp from 'public/src/services/schttp'
import { stringifyQueryString, setCookie } from '@shein/common-function'

const _log = (name, data = '') =>
  console.log(
    `%c ${name}`,
    'color: #fff; background: #007aff; padding: 2px 5px; border-radius: 2px;',
    data
  )

function abtHook(abt) {
  window.abtHook = (data, { log = true } = {}) => {
    return abt.getUserAbtResult(data).then(res => {
      log && _log('abt_log_info:', res)
      return res
    })
  }
  window.abtAnalysisHook = (...arg) => {
    const { sa } = abt.getUserAbtResultForAnalysis(...arg)
    _log('The obtained data is:', sa)
    return { sa }
  }
}

function _abtDebugHook() {
  if (!gbCommonInfo.isDebug) return // 只有debug模式下才会开启

  const abtMockHookObj = {}
  ;['get', 'set', 'delete', 'clear'].forEach(method => {
    abtMockHookObj[method] = async data => {
      const params = {}
      if (typeof data === 'string') {
        params.posKeys = data
      } else if (data && typeof data === 'object') {
        Object.keys(data).forEach(key => {
          const item = data[key]
          if (item.param && !item.p) {
            item.p = item.param
          }
          if (item.p && !item.param) {
            item.param = item.p
          }
        })
        params.data = data
      }
      const url = `/api/abt/mock/${method}`
      const res = await schttp({
        url,
        method: 'post',
        data: params
      })
      return res
    }
  })

  const bffMockHookObj = {
    prefix: '/abt/mock',
    async get() {
      return schttp({
        url: `${this.prefix}/get`,
        useBffApi: true,
        method: 'get'
      })
    },
    async set(data) {
      return schttp({
        url: `${this.prefix}/set`,
        method: 'post',
        useBffApi: true,
        data
      })
    },
    async delete(posKeys = '') {
      return schttp({
        url: `${this.prefix}/delete`,
        method: 'post',
        useBffApi: true,
        data: stringifyQueryString({ queryObj: { posKeys } }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
    },
    async clear() {
      return schttp({
        url: `${this.prefix}/clear`,
        useBffApi: true,
        method: 'post'
      })
    }
  }

  window.abtDevTools = {
    info: {
      version: '1.0.4',
      text: '已支持无痕模式mock'
    },
    abtMock: abtMockHookObj,
    bffMock: bffMockHookObj,
    // 请求原始请求数据curl
    debug: async data => {
      let params = {}
      if (typeof data === 'string') {
        params = { newPosKeys: data }
      } else {
        const { newPosKeys, posKeys } = data
        params = {
          newPosKeys,
          posKeys
        }
      }
      if (params.posKeys) {
        const WEB_BRAND = gbCommonInfo.WEB_CLIENT === 'romwe' ? 'R' : 'S'
        const TERMINAL = 'M'
        const prefix = `${WEB_BRAND}${TERMINAL}`
        params.posKeys = params.posKeys
          .split(',')
          .map(posKey => `${prefix}${posKey}`)
          .join(',')
      }
      return schttp({
        url: '/api/abt/test/query',
        method: 'post',
        data: params
      }).then(({ response: data }) => {
        const { curlCommand: curl, traceId } = data?.originData?.[0] || {}
        _log('Request result', data.data)
        _log('curl of the current original request', curl)
        return { data: data.data, curl, traceId }
      })
    }
  }

  window.abtMockHook = (...arg) => {
    window.abtDevTools.abtMock.set(...arg).then(res => {
      _log('abtMock set success 请刷新页面生效！', res)
    })
  }
  window.abtDebugHook = window.abtDevTools.debug
  instanceVueAbtDebug()
}

function instanceVueAbtDebug() {
  requestIdleCallback(async () => {
    if (!document.querySelector('#abtDevtools-style')) {
      if (location.href.includes('abtDebug=1')) {
        // eslint-disable-next-line @shein-aidc/common/notDirectUseCookie
        setCookie({ key: 'abtDebug', value: 1 })
        console.log('abtDebug开启中，将自动刷新页面')
        location.reload()
      }
      return
    }
    if (!document.querySelector('#abtDevtools-style')) return // 未开启devtools
    const { createAbtDevtoolsApp } = await import(
      '@shein-aidc/business-abt-devtools/mobile'
    )
    createAbtDevtoolsApp()
  })
}

function abtDebugInstance(abt) {
  try {
    if (typeof window === 'undefined') return
    abtHook(abt)
    _abtDebugHook()
  } catch (e) {
    //
  }
}

export { abtDebugInstance }
